import RichContent from '@pretto/bricks/website/about/components/RichContent'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BlocPictos = ({ buttonType, buttonLabel, buttonUrl, suptitle, title, content, pictos, ...props }) => (
  <S.Grid {...props}>
    <S.ContentWrapper>
      {suptitle && <S.Suptitle>{suptitle}</S.Suptitle>}

      <S.Title>{title}</S.Title>

      <RichContent allowedTypes={['strong']}>{content}</RichContent>

      {buttonType === 'cta' && <S.Button href={buttonUrl}>{buttonLabel}</S.Button>}
      {buttonType === 'pretto_search' && <S.PrettoSearch />}
    </S.ContentWrapper>

    <S.PictoWrapper>
      {pictos.map(({ picto, description }, index) => (
        <S.PictoItem key={index}>
          <S.PictoBackground>
            <S.Picto as={picto} />
          </S.PictoBackground>

          <RichContent allowedTypes={['strong']}>{description}</RichContent>
        </S.PictoItem>
      ))}
    </S.PictoWrapper>
  </S.Grid>
)

BlocPictos.propTypes = {
  /** Call to action button */
  buttonLabel: PropTypes.string,
  /** Type of button*/
  buttonType: PropTypes.string,
  /** Link url button */
  buttonUrl: PropTypes.string,
  /** Text content */
  content: PropTypes.string.isRequired,
  /** Picto svg & text */
  pictos: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      picto: PropTypes.elementType.isRequired,
    })
  ).isRequired,
  /** Text of suptitle */
  suptitle: PropTypes.string,
  /** Text of title */
  title: PropTypes.string.isRequired,
}

export default memo(BlocPictos)

import BaseButton from '@pretto/bricks/components/buttons/Button'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import BasePrettoSearch from '@pretto/bricks/website/about/components/PrettoSearch'
import styled from 'styled-components'

export const Button = styled(BaseButton).attrs({ isSecondary: true })`
  margin-top: ${g(4)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
  }
`

export const Grid = styled.div`
  ${grid()};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral4};
  padding: ${g(5)} 0;

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${g(10)} 0;
  }
`

export const ContentWrapper = styled.div`
  ${column([2, 4])};
  margin-bottom: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 3])};
    margin-bottom: 0;

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 4])};
  }
`

export const Picto = styled.svg`
  color: ${({ theme }) => theme.colors.primary1};
  height: ${g(3)};
  width: ${g(3)};
`

export const PictoBackground = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary4};
  border-radius: ${g(1)};
  display: flex;
  height: ${g(7)};
  justify-content: center;
  width: ${g(7)};
`

export const PictoItem = styled.div`
  align-items: center;
  display: grid;
  grid-column: span 1;
  grid-template-columns: ${g(7)} auto;
  column-gap: ${g(2)};
`

export const PictoWrapper = styled.div`
  ${column([2, 4])};
  align-self: center;
  display: grid;
  grid-gap: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([5, 5])};
    grid-gap: ${g(4)};
    grid-template-columns: 1fr 1fr;
    padding-left: ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([7, 6])};
  }
`

export const PrettoSearch = styled(BasePrettoSearch)`
  grid-gap: ${g(2)};
  grid-template: auto auto / auto;
  justify-content: center;
  justify-items: center;
  margin-top: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: flex-start;
    justify-items: flex-start;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-gap: ${g(3)};
    grid-template: auto / auto auto;
  }
`

export const Suptitle = styled(BaseTypo).attrs({ typo: 'headline16' })`
  margin-bottom: ${g(2)};
`

export const Title = styled(BaseTypo).attrs({ forwardedAs: 'h2', typo: 'heading32' })`
  margin-bottom: ${g(3)};
`
